import React from "react"
import { InstagramFeed } from "../components/InstagramFeed"


    const SocialWall = ({ data, location }) => {
    return(
        <div>
        <InstagramFeed />
    </div>

    )
}



export default SocialWall